import { Box } from '@mui/material';
import { data } from 'azure-maps-control';
import React, { Component } from 'react';
import { Localization } from '../../../../localization/Localization';
import { ProgrammingStatus } from '../../../../shared/components/ActionsMenu/models/ProgrammingStatus';
import { MeasurementSystemType } from '../../../../shared/models/MeasurementSystemType';
import { Point } from '../../../../shared/models/Point';
import ToastService from '../../../../ToastService';
import BusinessMessages from '../../../../utils/BusinessMessages';
import { MeasurementSystem } from '../../../../utils/MeasurementSystem';
import { RouteComponentProps, withRouter } from '../../../../withRouter';
import { ProjectVersion } from '../../../Home/services/dataContracts/queryStack/ProjectVersion';
import { GetRoadWorksRequestArgs } from '../../../Programmings/services/dataContracts/controller/GetRoadWorksRequestArgs';
import { FilteredProgramming } from '../../../Programmings/services/dataContracts/queryStack/FilteredProgramming';
import { ProgrammingsApiClient } from '../../../Programmings/services/ProgrammingsApiClient';
import { MergedProjectVersion } from '../../../RoadsCondition/models/MergedProjectVersion';
import { OtherAttributes } from '../../../RoadsCondition/models/OtherAttributes';
import { RouteLocationStateModel } from '../../../RoadsCondition/models/RouteLocationStateModel';
import { RoadsConditionAndScenariosShared } from '../../../RoadsCondition/RoadsConditionAndScenariosShared';
import { Environment } from '../../../RoadsCondition/services/RoadsCondition/dataContracts/queryStack/Environment';
import { Hierarchy } from '../../../RoadsCondition/services/RoadsCondition/dataContracts/queryStack/Hierarchy';
import { Manager } from '../../../RoadsCondition/services/RoadsCondition/dataContracts/queryStack/Manager';
import { StepImageAnomalies } from '../../../RoadsCondition/services/RoadsCondition/dataContracts/queryStack/StepImageAnomalies';
import { Traffic } from '../../../RoadsCondition/services/RoadsCondition/dataContracts/queryStack/Traffic';
import { getHiddenIsSelectedForSort, RoadSection } from '../../models/RoadSection';
import { UpdateScenarioSectionsRequestArgs } from '../../services/dataContracts/controller/UpdateScenarioSectionsRequestArgs';
import { Scenario } from '../../services/dataContracts/queryStack/Scenario';
import { ScenariosApiClient } from '../../services/ScenariosApiClient';
import { ActionsMenuComponent } from '../CommonComponents/ActionsMenuComponent';
import { HeaderComponent } from '../CommonComponents/HeaderComponent';
import { SectionsSummaryComponent } from '../CommonComponents/SectionsSummaryComponent';
import { SectionsSummaryModel } from '../CommonComponents/SectionsSummaryModel';
import ScenariosUtilities from '../ScenariosUtilities';
import { MaintenanceScenarioMapComponent } from './components/MaintenanceScenarioMapComponent';
import { SectionsSelectorComponent } from './components/SectionsSelectorComponent';
import './ScenarioSectionsManagementStyles.scss';

interface ScenarioSectionsManagementViewState {
    loading: boolean,
    isSectionsDrawerOpened: boolean,
    mergedProject: MergedProjectVersion,
    selectedScenario: Scenario,
    selectedScenarioSections: Map<number, RoadSection>,
    currentCurrency: string,
    sections: Map<number, RoadSection>,
    filterdSectionsIds: Set<number>,
    selectedSectionsIds: Set<number>,
    oldScenarioSelectedSectionsIds: Set<number>,
    sectionsSummaryModel: SectionsSummaryModel,
    filterdAndSelectedSectionsPositions: data.Position[],
    activeAnomalies: Set<string>,
    activeQualities: Set<number>,
    activeMunicipalities: Set<string>,
    activeDistricts: Set<string>,
    activeCollaborativeDevelopmentZones: Set<string>,
    activeHierarchies: Set<Hierarchy>,
    activeTraffics: Set<Traffic>,
    activeEnvironments: Set<Environment>,
    activeManagers: Set<Manager>,
    activeImportances: Set<string>,
    activeOtherAttributes: Set<string>,
    measurementSystemType: MeasurementSystemType,
    selectedProgrammings: string[],
    selectedYears: number[],
    activeStatus: Set<ProgrammingStatus>,
    filteredProgrammingsFromFilter: FilteredProgramming[],
    filteredSectionsIdsFromGrid: Set<number>
    perStepImagesAnomalies: Map<number, StepImageAnomalies[]>,
    projectVersionAnomalies: Map<string, string>
}

const initialState: ScenarioSectionsManagementViewState = {
    loading: false,
    isSectionsDrawerOpened: true,
    mergedProject: null,
    selectedScenario: null,
    selectedScenarioSections: new Map<number, RoadSection>(),
    currentCurrency: null,
    sections: new Map<number, RoadSection>(),
    filterdSectionsIds: new Set<number>(),
    selectedSectionsIds: new Set<number>(),
    oldScenarioSelectedSectionsIds: new Set<number>(),
    sectionsSummaryModel: null,
    filterdAndSelectedSectionsPositions: [],
    activeAnomalies: new Set<string>(),
    activeQualities: new Set<number>([]),
    activeMunicipalities: new Set<string>(),
    activeDistricts: new Set<string>(),
    activeCollaborativeDevelopmentZones: new Set<string>(),
    activeHierarchies: new Set<Hierarchy>(),
    activeTraffics: new Set<Traffic>(),
    activeEnvironments: new Set<Environment>(),
    activeManagers: new Set<Manager>(),
    activeImportances: new Set<string>(),
    activeOtherAttributes: new Set<string>(),
    measurementSystemType: null,
    selectedProgrammings: [],
    selectedYears: [],
    activeStatus: new Set<ProgrammingStatus>(),
    filteredProgrammingsFromFilter: null,
    filteredSectionsIdsFromGrid: new Set<number>([]),
    perStepImagesAnomalies: new Map<number, StepImageAnomalies[]>(),
    projectVersionAnomalies: new Map<string, string>()
}

export class ScenarioSectionsManagementView extends Component<RouteComponentProps, ScenarioSectionsManagementViewState> {
    _isMounted: boolean;
    projectId: string;
    projectVersionId: number;
    locationGeometry: Point;
    mergedProjectAuscultationsCache: Map<number, MergedProjectVersion>;
    projectVersionsCache: Map<number, ProjectVersion>;

    constructor(props) {
        super(props);

        this.mergedProjectAuscultationsCache = new Map<number, MergedProjectVersion>();
        this.projectVersionsCache = new Map<number, ProjectVersion>();

        initialState.measurementSystemType = MeasurementSystem.getCurrentType();
        initialState.activeQualities = RoadsConditionAndScenariosShared.getInitialActiveQualities();

        this.state = initialState;
    }

    async componentDidMount() {
        this._isMounted = true;

        let locationState = this.props.location.state as RouteLocationStateModel;
        if (!locationState) {
            setTimeout(() => this.props.navigate("/"));
            return;
        }

        this.projectId = locationState.projectId;
        this.projectVersionId = locationState.projectVersionId;
        this.locationGeometry = locationState.locationGeometry;

        const query = new URLSearchParams(this.props.location.search);
        const scenarioId = Number(query.get('scenarioId'));

        this.setState({
            loading: true
        });

        let data = await ScenariosUtilities.getScenarioAndProjectSettingsData(this.projectId, scenarioId, this.mergedProjectAuscultationsCache, this.projectVersionsCache);

        let scenario = data.scenario;
        let mergedProject = data.mergedProject;
        let projectCurrency = data.projectCurrency;
        let workPriorities = data.workPriorities;
        let costRatios = data.costRatios;

        let sections = new Map<number, RoadSection>();
        let selectedScenarioSections = new Map<number, RoadSection>();
        let filterdSectionsIds = new Set<number>();
        let selectedSectionsIds = new Set<number>();
        let oldScenarioSelectedSectionsIds = new Set<number>();

        mergedProject.roadsSections.forEach((element) => {
            let sectionId = element.roadSectionId;
            let isSelected: boolean = false;
            let section = { ...element } as RoadSection;
            if (scenario.sections.map(e => e.roadSectionId).includes(sectionId)) {
                selectedScenarioSections.set(sectionId, section);
                isSelected = true;
                selectedSectionsIds.add(sectionId);
                oldScenarioSelectedSectionsIds.add(sectionId);
            }

            let sectionData = ScenariosUtilities.getSectionDataWhenHasImportance(section.importance, section.score, section.traffic, workPriorities, costRatios);
            ScenariosUtilities.updateSectionData(section, sectionData);

            sections.set(sectionId, { ...section, isSelected: isSelected, hiddenIsSelectedForSort: getHiddenIsSelectedForSort(isSelected), isVisible: true });
            filterdSectionsIds.add(sectionId);
        });

        let sectionsSummaryModel = ScenariosUtilities.computeSummary(selectedSectionsIds, mergedProject.roadsSections, sections);

        this.setState({
            mergedProject,
            selectedScenario: scenario,
            selectedScenarioSections: selectedScenarioSections,
            currentCurrency: projectCurrency,
            sections: sections,
            filterdSectionsIds: filterdSectionsIds,
            selectedSectionsIds: selectedSectionsIds,
            oldScenarioSelectedSectionsIds: oldScenarioSelectedSectionsIds,
            sectionsSummaryModel: sectionsSummaryModel,
            loading: false
        });

    }

    updateSelectedSections = (selectedSectionsIds: Set<number>, sections: Map<number, RoadSection>, state: ScenarioSectionsManagementViewState): void => {
        let filterdAndSelectedSectionsIds = this.mergeFilterdAndSelectedSectionsIds(selectedSectionsIds, state.filterdSectionsIds);
        let filterdAndSelectedSectionsPositions = RoadsConditionAndScenariosShared.getDisplayedSectionsPositions(filterdAndSelectedSectionsIds, state.mergedProject.roadsSections);
        let sectionsSummaryModel = ScenariosUtilities.computeSummary(selectedSectionsIds, state.mergedProject.roadsSections, sections);

        this.setState({
            sections: new Map<number, RoadSection>(sections),
            selectedSectionsIds: selectedSectionsIds,
            sectionsSummaryModel: sectionsSummaryModel,
            filterdAndSelectedSectionsPositions: filterdAndSelectedSectionsPositions
        });
    }

    handleSaveScenarioSections = (state: ScenarioSectionsManagementViewState): void => {
        let scenarioId = state.selectedScenario.scenarioId;
        let sectionsIds = Array.from(state.sections).filter(x => x[1].isSelected).map(x => x[0]);
        let requestArgs: UpdateScenarioSectionsRequestArgs = {
            scenarioId: scenarioId,
            ianaTimeZoneId: Localization.ianaTimeZoneId,
            sectionsIds: sectionsIds
        };

        this.setState({
            loading: true
        });

        ScenariosApiClient.UpdateScenarioSections(requestArgs)
            .then((res) => {
                let data = res.data;
                let errors = BusinessMessages.GetErrors(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);

                    this.setState({
                        loading: false
                    });

                    return;
                }

                this.navigateToScenarioSectionsVisualisation(scenarioId);
            });
    }

    handleCancelUpdateScenarioSections = (state: ScenarioSectionsManagementViewState): void => {
        this.navigateToScenarioSectionsVisualisation(state.selectedScenario.scenarioId);
    }

    handleCancel = (state: ScenarioSectionsManagementViewState): void => {
        this.navigateToScenarioSectionsVisualisation(state.selectedScenario.scenarioId);
    }

    navigateToScenarioSectionsVisualisation = (scenarioId: number): void => {
        let urlRedirect = `/ScenarioSectionsVisualisation?scenarioId=${scenarioId}`;
        let locationState = this.props.location.state as RouteLocationStateModel;
        this.props.navigate(urlRedirect, { state: locationState });
    }

    handleSectionsDrawerClosed = (): void => {
        this.setState({
            isSectionsDrawerOpened: false
        });
    }

    handleSectionsDrawerOpened = (): void => {
        this.setState({
            isSectionsDrawerOpened: true
        });
    }

    handleSectionFilteredChanged = (filteredSectionsIdsFromGrid: Set<number>, state: ScenarioSectionsManagementViewState): void => {
        let sections = state.sections;
        let filterdSectionsIds = this.updateSectionsVisibility(sections, state.activeQualities, filteredSectionsIdsFromGrid, state.activeMunicipalities, state.activeDistricts, state.activeCollaborativeDevelopmentZones, state.activeHierarchies, state.activeTraffics, state.activeEnvironments, state.activeManagers, state.activeImportances, state.activeOtherAttributes, state);
        let filterdAndSelectedSectionsIds = this.mergeFilterdAndSelectedSectionsIds(state.selectedSectionsIds, filterdSectionsIds);
        let filterdAndSelectedSectionsPositions = RoadsConditionAndScenariosShared.getDisplayedSectionsPositions(filterdAndSelectedSectionsIds, state.mergedProject.roadsSections);

        this.setState({
            sections: sections,
            filterdSectionsIds: filterdSectionsIds,
            filterdAndSelectedSectionsPositions: filterdAndSelectedSectionsPositions,
            filteredSectionsIdsFromGrid: filteredSectionsIdsFromGrid
        });
    }

    handleDisplayDetections = (activeAnomalies: Set<string>): void => {
        this.setState({
            activeAnomalies: activeAnomalies
        });
    }

    handleDisplaySectionsFromQualityFilters = (activeQualities: Set<number>, state: ScenarioSectionsManagementViewState): void => {
        let sections = state.sections;
        let filterdSectionsIds = this.updateSectionsVisibility(sections, activeQualities, state.filteredSectionsIdsFromGrid, state.activeMunicipalities, state.activeDistricts, state.activeCollaborativeDevelopmentZones, state.activeHierarchies, state.activeTraffics, state.activeEnvironments, state.activeManagers, state.activeImportances, state.activeOtherAttributes, state);
        let filterdAndSelectedSectionsIds = this.mergeFilterdAndSelectedSectionsIds(state.selectedSectionsIds, filterdSectionsIds);
        let filterdAndSelectedSectionsPositions = RoadsConditionAndScenariosShared.getDisplayedSectionsPositions(filterdAndSelectedSectionsIds, state.mergedProject.roadsSections);

        this.setState({
            sections: sections,
            filterdSectionsIds: filterdSectionsIds,
            filterdAndSelectedSectionsPositions: filterdAndSelectedSectionsPositions,
            activeQualities: activeQualities
        });
    }

    handleDisplaySectionsFromGeographiesFilters = (activeMunicipalities: Set<string>, activeDistricts: Set<string>, activeCollaborativeDevelopmentZones: Set<string>, state: ScenarioSectionsManagementViewState): void => {
        let sections = state.sections;
        let filterdSectionsIds = this.updateSectionsVisibility(sections, state.activeQualities, state.filteredSectionsIdsFromGrid, activeMunicipalities, activeDistricts, activeCollaborativeDevelopmentZones, state.activeHierarchies, state.activeTraffics, state.activeEnvironments, state.activeManagers, state.activeImportances, state.activeOtherAttributes, state);
        let filterdAndSelectedSectionsIds = this.mergeFilterdAndSelectedSectionsIds(state.selectedSectionsIds, filterdSectionsIds);
        let filterdAndSelectedSectionsPositions = RoadsConditionAndScenariosShared.getDisplayedSectionsPositions(filterdAndSelectedSectionsIds, state.mergedProject.roadsSections);

        this.setState({
            sections: sections,
            filterdSectionsIds: filterdSectionsIds,
            filterdAndSelectedSectionsPositions: filterdAndSelectedSectionsPositions,
            activeMunicipalities: activeMunicipalities,
            activeDistricts: activeDistricts,
            activeCollaborativeDevelopmentZones: activeCollaborativeDevelopmentZones
        });
    }

    handleDisplaySectionsFromAttributesFilters = (activeHierarchies: Set<Hierarchy>, activeTraffics: Set<Traffic>, activeEnvironments: Set<Environment>, activeManagers: Set<Manager>, activeImportances: Set<string>, activeOtherAttributes: Set<string>, state: ScenarioSectionsManagementViewState): void => {
        let sections = state.sections;
        let filterdSectionsIds = this.updateSectionsVisibility(sections, state.activeQualities, state.filteredSectionsIdsFromGrid, state.activeMunicipalities, state.activeDistricts, state.activeCollaborativeDevelopmentZones, activeHierarchies, activeTraffics, activeEnvironments, activeManagers, activeImportances, activeOtherAttributes, state);
        let filterdAndSelectedSectionsIds = this.mergeFilterdAndSelectedSectionsIds(state.selectedSectionsIds, filterdSectionsIds);
        let filterdAndSelectedSectionsPositions = RoadsConditionAndScenariosShared.getDisplayedSectionsPositions(filterdAndSelectedSectionsIds, state.mergedProject.roadsSections);

        this.setState({
            sections: sections,
            filterdSectionsIds: filterdSectionsIds,
            filterdAndSelectedSectionsPositions: filterdAndSelectedSectionsPositions,
            activeHierarchies: activeHierarchies,
            activeTraffics: activeTraffics,
            activeEnvironments: activeEnvironments,
            activeManagers: activeManagers,
            activeImportances: activeImportances,
            activeOtherAttributes: activeOtherAttributes
        });
    }

    updateSectionsVisibility = (sections: Map<number, RoadSection>, activeQualities: Set<number>, filteredSectionsIdsFromGrid: Set<number>, activeMunicipalities: Set<string>, activeDistricts: Set<string>, activeCollaborativeDevelopmentZones: Set<string>, activeHierarchies: Set<Hierarchy>, activeTraffics: Set<Traffic>, activeEnvironments: Set<Environment>, activeManagers: Set<Manager>, activeImportances: Set<string>, activeOtherAttributes: Set<string>, state: ScenarioSectionsManagementViewState): Set<number> => {
        let filterdSectionsIds = new Set<number>(state.filterdSectionsIds);

        sections.forEach((section) => {
            if ((activeQualities.size > 0 && activeQualities.has(section.score)) &&
                ((activeMunicipalities.size > 0 && activeMunicipalities.has(section.municipality)) || activeMunicipalities.size === 0) &&
                ((activeDistricts.size > 0 && activeDistricts.has(section.district)) || activeDistricts.size === 0) &&
                ((activeCollaborativeDevelopmentZones.size > 0 && activeCollaborativeDevelopmentZones.has(section.collaborativeDevelopmentZone)) || activeCollaborativeDevelopmentZones.size === 0) &&
                ((activeHierarchies.size > 0 && activeHierarchies.has(section.hierarchy)) || activeHierarchies.size === 0) &&
                ((activeTraffics.size > 0 && activeTraffics.has(section.traffic)) || activeTraffics.size === 0) &&
                ((activeEnvironments.size > 0 && activeEnvironments.has(section.environment)) || activeEnvironments.size === 0) &&
                ((activeManagers.size > 0 && activeManagers.has(section.manager)) || activeManagers.size === 0) &&
                ((activeImportances.size > 0 && activeImportances.has(section.importance ? section.importance.toString() : null)) || activeImportances.size === 0) &&
                ((activeOtherAttributes.size > 0 && (
                    (section.bus && activeOtherAttributes.has(OtherAttributes.Bus)) ||
                    (section.bikeLase && activeOtherAttributes.has(OtherAttributes.BikeLase)) ||
                    (section.border && activeOtherAttributes.has(OtherAttributes.Border)) ||
                    (section.ditch && activeOtherAttributes.has(OtherAttributes.Ditch)) ||
                    (section.side && activeOtherAttributes.has(OtherAttributes.Side)) ||
                    (!section.bus && !section.bikeLase && !section.border && !section.ditch && !section.side && activeOtherAttributes.has(null))
                )) || activeOtherAttributes.size === 0) &&
                ((filteredSectionsIdsFromGrid.size > 0 && filteredSectionsIdsFromGrid.has(section.roadSectionId)) || filteredSectionsIdsFromGrid.size === 0)) {

                section.isVisible = true;

                if (!filterdSectionsIds.has(section.roadSectionId)) {
                    filterdSectionsIds.add(section.roadSectionId);
                }
            }
            else {
                section.isVisible = false;

                if (filterdSectionsIds.has(section.roadSectionId) && !state.selectedSectionsIds.has(section.roadSectionId)) {
                    filterdSectionsIds.delete(section.roadSectionId);
                }
            }
        });

        return filterdSectionsIds;
    }

    onSelectedSectionChange = (sectionsIds: number[], state: ScenarioSectionsManagementViewState): void => {
        let selectedSectionsIds = new Set<number>(sectionsIds);

        let sections = state.sections;
        sections.forEach((section) => {
            if (selectedSectionsIds.has(section.roadSectionId)) {
                section.isSelected = true;
                section.hiddenIsSelectedForSort = getHiddenIsSelectedForSort(true);
            }
            else {
                section.isSelected = false;
                section.hiddenIsSelectedForSort = getHiddenIsSelectedForSort(false);
            }
        });

        let sectionsSummaryModel = ScenariosUtilities.computeSummary(selectedSectionsIds, state.mergedProject.roadsSections, sections);

        this.setState({
            sections: new Map<number, RoadSection>(sections),
            selectedSectionsIds: selectedSectionsIds,
            sectionsSummaryModel: sectionsSummaryModel
        });
    }

    handleUpdateScenarioClicked = (state: ScenarioSectionsManagementViewState): void => {
        let urlRedirect = `/ScenariosManagement?scenarioId=${state.selectedScenario.scenarioId}`;
        let locationState = this.props.location.state as RouteLocationStateModel;
        this.props.navigate(urlRedirect, { state: locationState });
    }

    mergeFilterdAndSelectedSectionsIds(...sets) {
        const mergedSet = new Set<number>();

        for (const currentSet of sets) {
            for (const element of currentSet) {
                mergedSet.add(element);
            }
        }

        return mergedSet;
    }

    handleMeasurementSystemTypeChanged = (measurementSystemType: MeasurementSystemType): void => {
        this.setState({
            measurementSystemType
        });
    }

    handleDisplayAreasFromWorksFilter = (selectedProgrammings: string[], selectedYears: number[], activeStatus: Set<ProgrammingStatus>): void => {
        this.setState({
            loading: true
        });

        let status: number[] = Array.from(activeStatus).map(x => {
            return x === ProgrammingStatus.finished ? 2 : (x === ProgrammingStatus.toBeCompleted ? 1 : null);
        });

        let data: GetRoadWorksRequestArgs = {
            years: selectedYears,
            labels: selectedProgrammings,
            status
        };

        ProgrammingsApiClient.GetRoadWorksByFilters(this.projectId, data)
            .then((res) => {
                this.setState({
                    filteredProgrammingsFromFilter: res.data,
                    selectedProgrammings,
                    selectedYears,
                    activeStatus, loading: false
                });
            });
    }

    setAnomaliesData = ({ anomalies, perStepImagesAnomaliesMap }: { anomalies: Map<string, string>, perStepImagesAnomaliesMap: Map<number, StepImageAnomalies[]> }): void => {
        this.setState({
            projectVersionAnomalies: anomalies,
            perStepImagesAnomalies: perStepImagesAnomaliesMap
        })
    }

    render() {
        const state = this.state;

        return (
            <Box className="maintenance_scenario">
                <HeaderComponent selectedScenario={state.selectedScenario} loading={state.loading} handleCancel={() => this.handleCancel(state)} handleUpdateScenarioClicked={() => this.handleUpdateScenarioClicked(state)} />
                <ActionsMenuComponent
                    projectId={this.projectId}
                    activeAnomalies={state.activeAnomalies}
                    activeQualities={state.activeQualities}
                    activeMunicipalities={state.activeMunicipalities}
                    activeDistricts={state.activeDistricts}
                    activeCollaborativeDevelopmentZones={state.activeCollaborativeDevelopmentZones}
                    activeHierarchies={state.activeHierarchies}
                    activeTraffics={state.activeTraffics}
                    activeEnvironments={state.activeEnvironments}
                    activeManagers={state.activeManagers}
                    activeImportances={state.activeImportances}
                    activeOtherAttributes={state.activeOtherAttributes}
                    mergedProject={state.mergedProject}
                    loading={state.loading}
                    isSectionsDrawerOpened={state.isSectionsDrawerOpened}
                    selectedProgrammings={state.selectedProgrammings}
                    selectedYears={state.selectedYears}
                    activeStatus={state.activeStatus}
                    disabledSearch={true}
                    handleSectionsDrawerClosed={this.handleSectionsDrawerClosed}
                    handleSectionsDrawerOpened={this.handleSectionsDrawerOpened}
                    handleDisplayDetections={(activeAnomalies: Set<string>) => this.handleDisplayDetections(activeAnomalies)}
                    handleDisplaySections={(activeQualities: Set<number>) => this.handleDisplaySectionsFromQualityFilters(activeQualities, state)}
                    handleDisplaySectionsFromGeographiesFilters={(activeMunicipalities: Set<string>, activeDistricts: Set<string>, activeCollaborativeDevelopmentZones: Set<string>) => this.handleDisplaySectionsFromGeographiesFilters(activeMunicipalities, activeDistricts, activeCollaborativeDevelopmentZones, state)}
                    handleDisplaySectionsFromAttributesFilters={(activeHierarchies: Set<Hierarchy>, activeTraffics: Set<Traffic>, activeEnvironments: Set<Environment>, activeManagers: Set<Manager>, activeImportances: Set<string>, activeOtherAttributes: Set<string>) => this.handleDisplaySectionsFromAttributesFilters(activeHierarchies, activeTraffics, activeEnvironments, activeManagers, activeImportances, activeOtherAttributes, state)}
                    handleDisplayAreasFromWorksFilter={this.handleDisplayAreasFromWorksFilter}
                    setAnomaliesData={this.setAnomaliesData}
                    projectVersionAnomalies={state.projectVersionAnomalies}
                />
                <Box display="flex" flexDirection="row" height="100%">
                    {state.isSectionsDrawerOpened &&
                        <SectionsSelectorComponent selectedScenario={state.selectedScenario}
                            currency={state.currentCurrency}
                            sections={state.sections}
                            selectedSectionsIds={state.selectedSectionsIds}
                            loading={state.loading}
                            isSectionsDrawerOpened={state.isSectionsDrawerOpened}
                            measurementSystemType={state.measurementSystemType}
                            updateSelectedSections={(selectedSectionsIds: Set<number>, sections: Map<number, RoadSection>) => this.updateSelectedSections(selectedSectionsIds, sections, state)}
                            handleSaveScenarioSections={() => this.handleSaveScenarioSections(state)}
                            handleCancelUpdateScenarioSections={() => this.handleCancelUpdateScenarioSections(state)}
                            handleSectionFilteredChanged={(filteredSectionsIdsFromGrid) => this.handleSectionFilteredChanged(filteredSectionsIdsFromGrid, state)}
                        />
                    }
                    {this.locationGeometry &&
                        <MaintenanceScenarioMapComponent
                            locationGeometry={this.locationGeometry}
                            mergedProject={state.mergedProject}
                            filterdAndSelectedSectionsPositions={state.filterdAndSelectedSectionsPositions}
                            filterdSectionsIds={state.filterdSectionsIds}
                            selectedSectionsIds={state.selectedSectionsIds}
                            activeAnomalies={state.activeAnomalies}
                            loading={state.loading}
                            isSectionsDrawerOpened={state.isSectionsDrawerOpened}
                            onSelectedSectionChange={(sectionsIds) => this.onSelectedSectionChange(sectionsIds, state)}
                            currentMeasurementSystemType={state.measurementSystemType}
                            filteredProgrammingsFromFilter={state.filteredProgrammingsFromFilter}
                            perStepImagesAnomalies={state.perStepImagesAnomalies}
                        />
                    }
                    {state.sectionsSummaryModel && <SectionsSummaryComponent selectedScenario={state.selectedScenario} sectionsSummaryModel={state.sectionsSummaryModel} sections={state.sections} currency={state.currentCurrency} inEdit={true} measurementSystemType={state.measurementSystemType} />}
                </Box>
            </Box>
        );
    }
}

export default React.forwardRef(withRouter(ScenarioSectionsManagementView));
