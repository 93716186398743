import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Button, Checkbox, ClickAwayListener, FormControlLabel, Popper } from '@mui/material';
import { Box } from '@mui/system';
import React, { ChangeEvent, MutableRefObject, useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { ScaleLoader } from 'react-spinners';
import Translate from '../../../localization/Localization';
import { FollowUpWorksApiClient } from '../../../views/FollowUpWorks/services/FollowUpWorksApiClient';
import { ProgrammingExtended } from '../../../views/Programmings/models/ProgrammingExtended';
import { Programming } from '../../../views/Programmings/services/dataContracts/queryStack/Programming';
import { ProgrammingStatus } from './models/ProgrammingStatus';
import './WorksFilterStyles.scss';

interface SelectOptionModel {
    label: string,
    value: string | number
}

interface WorksFilterComponentProps {
    open: boolean,
    anchorEl: null | HTMLElement,
    projectId?: string,
    programmings?: Programming[],
    selectedProgrammings: string[],
    selectedYears: number[],
    activeStatus: Set<ProgrammingStatus>,
    selectedProgramming?: ProgrammingExtended,
    shouldDisplaySecondMessage: boolean,
    onClose: () => void,
    handleDisplayAreasFromWorksFilter: (selectedProgrammings: string[], selectedYears: number[], activeStatus: Set<ProgrammingStatus>) => void
}

export const WorksFilterComponent = (props: WorksFilterComponentProps): JSX.Element => {

    const [loading, setLoading] = useState<boolean>(false);
    const [isStatusOfWorksChoicesOpened, setIsStatusOfWorksChoicesOpened] = useState<boolean>(true);
    const [isProgrammingsListOpened, setIsProgrammingsListOpened] = useState<boolean>(true);
    const [yearsOptions, setYearsOptions] = useState<SelectOptionModel[]>([]);
    const [yearsValues, setYearsValues] = useState<SelectOptionModel[]>(null);
    const [programmingsOptions, setProgrammingsOptions] = useState<SelectOptionModel[]>([]);
    const [programmingsValues, setProgrammingsValues] = useState<SelectOptionModel[]>(null);
    const [activeStatus, setActiveStatus] = useState(new Set<ProgrammingStatus>());
    const [isAllStatusCheckboxIndeterminate, setIsAllStatusCheckboxIndeterminate] = useState<boolean>(false);

    let checkboxRef: MutableRefObject<HTMLInputElement> = useRef(null);

    useEffect(() => {
        let programmingOptions: SelectOptionModel[] = [];
        let yearOptions: SelectOptionModel[] = [];

        if (props.programmings) {
            let options = getProgrammingsAndYearOptions(props.programmings);
            setProgrammingsOptions(options.programmingOptions.sort());
            setYearsOptions(options.yearOptions.sort());
        }
        else {
            setLoading(true);
            FollowUpWorksApiClient.GetRoadWorksFilterData(props.projectId)
                .then((res) => {
                    let programmings = res.data;
                    programmings.forEach((programming) => {
                        if ((props.selectedProgramming && programming.programmingId !== props.selectedProgramming.programmingId) || !props.selectedProgramming) {
                            let label = `${programming.year} - ${programming.label}`;
                            if (!programmingOptions.some(x => x.value === label)) {
                                programmingOptions.push({ label: label, value: programming.label });
                            }

                            let year = programming.year;
                            if (!yearOptions.some(x => x.value === year)) {
                                yearOptions.push({ label: `${year}`, value: year });
                            }
                        }
                    });

                    setLoading(false);
                    setProgrammingsOptions(programmingOptions.sort());
                    setYearsOptions(yearOptions.sort());
                });
        }
    }, [])

    useEffect(() => {
        if (yearsOptions.length > 0 && programmingsOptions.length > 0) {
            setProgrammingsValues(props.selectedProgrammings.map(x => { return { label: x, value: x } }));
            setYearsValues(props.selectedYears.map(x => { return { label: `${x}`, value: x } }));
            setActiveStatus(props.activeStatus);
        }
    }, [yearsOptions, programmingsOptions])

    const getProgrammingsAndYearOptions = (programmings: Programming[]): { programmingOptions: SelectOptionModel[], yearOptions: SelectOptionModel[] } => {
        let programmingOptions: SelectOptionModel[] = [];
        let yearOptions: SelectOptionModel[] = [];

        programmings.forEach((programming) => {
            let label = `${programming.year} - ${programming.label}`;
            if (!programmingOptions.some(x => x.value === label)) {
                programmingOptions.push({ label: `${label}`, value: label });
            }

            let year = programming.year;
            if (!yearOptions.some(x => x.value === year)) {
                yearOptions.push({ label: `${year}`, value: year });
            }
        });

        return {
            programmingOptions,
            yearOptions
        }
    }

    const handleYearsSelected = (e: SelectOptionModel[]): void => {
        setYearsValues(e);
    }

    const handleProgrammingsSelected = (e: SelectOptionModel[]): void => {
        setProgrammingsValues(e);
    }

    const handleStatusChanged = (e: ChangeEvent<HTMLInputElement>, status: ProgrammingStatus): void => {
        let activeStatusSet = new Set(activeStatus);

        let checked = e.target.checked;
        if (checked) {
            activeStatusSet.add(status);
        }
        else {
            activeStatusSet.delete(status);
        }

        setActiveStatus(activeStatusSet);

        let isIndeterminate = (activeStatusSet.has(ProgrammingStatus.finished) && activeStatusSet.has(ProgrammingStatus.toBeCompleted)) ||
            (!activeStatusSet.has(ProgrammingStatus.finished) && !activeStatusSet.has(ProgrammingStatus.toBeCompleted)) ? false : true;
        setIsAllStatusCheckboxIndeterminate(isIndeterminate);
    }

    const handleAllStatusChecked = (e: ChangeEvent<HTMLInputElement>): void => {
        let checked = e.target.checked;
        if (checked) {
            setActiveStatus(new Set([ProgrammingStatus.finished, ProgrammingStatus.toBeCompleted]));
        }
        else {
            setActiveStatus(new Set());
        }
        setIsAllStatusCheckboxIndeterminate(false);
    }

    const handleValidate = (): void => {
        let selectedProgrammings: string[] = programmingsValues ? programmingsValues.map(x => x.value as string) : [];
        let selectedYears: number[] = yearsValues ? yearsValues.map(x => x.value as number) : [];

        props.handleDisplayAreasFromWorksFilter(selectedProgrammings, selectedYears, activeStatus);
    }

    let ret: JSX.Element = null;

    if (loading) {
        ret = <ScaleLoader width={5} height={20} radius={50} color="#000000" />;
    }
    else {
        let partialReturn = null;
        if (programmingsOptions.length > 0) {
            partialReturn = <>
                <Box className="status-choices">
                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                        <FormControlLabel
                            control={<Checkbox ref={checkboxRef} indeterminate={isAllStatusCheckboxIndeterminate} checked={activeStatus.has(ProgrammingStatus.finished) && activeStatus.has(ProgrammingStatus.toBeCompleted)} onChange={(e) => handleAllStatusChecked(e)} />}
                            label={Translate.Resources.UI_WorksFilter_StatusOfWorks}
                            className="form-control-label-checkbox"
                        />
                        {isStatusOfWorksChoicesOpened ? <ExpandLessIcon onClick={() => setIsStatusOfWorksChoicesOpened(false)} className="expand-icon" /> :
                            <ExpandMoreIcon onClick={() => setIsStatusOfWorksChoicesOpened(true)} className="expand-icon" />}
                    </Box>
                    {isStatusOfWorksChoicesOpened &&
                        <Box display="flex" flexDirection="column" ml="10px" mt="10px">
                            <FormControlLabel
                                control={<Checkbox checked={activeStatus.has(ProgrammingStatus.toBeCompleted)} onChange={(e) => handleStatusChanged(e, ProgrammingStatus.toBeCompleted)} />}
                                label={Translate.Resources.UI_WorksFilter_ToBeCompleted}
                                className="form-control-label-checkbox"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={activeStatus.has(ProgrammingStatus.finished)} onChange={(e) => handleStatusChanged(e, ProgrammingStatus.finished)} />}
                                label={Translate.Resources.UI_WorksFilter_Finished}
                                className="form-control-label-checkbox"
                            />
                        </Box>
                    }
                </Box>
                <Box className="years">
                    <Select
                        className="select"
                        menuShouldBlockScroll={true}
                        options={yearsOptions}
                        value={yearsValues}
                        placeholder=""
                        onChange={(e) => handleYearsSelected(e as SelectOptionModel[])}
                        isMulti
                    />
                </Box>
                <Box display="flex" flexDirection="column">
                    <Box className="programming-label" >
                        <div className="programme">{Translate.Resources.UI_WorksFilter_Programme}</div>
                        {isProgrammingsListOpened ? <ExpandLessIcon onClick={() => setIsProgrammingsListOpened(false)} className="expand-icon" /> :
                            <ExpandMoreIcon onClick={() => setIsProgrammingsListOpened(true)} className="expand-icon" />}
                    </Box>
                    {isProgrammingsListOpened &&
                        <Box className="programmings">
                            <Select
                                className="select"
                                menuShouldBlockScroll={true}
                                options={programmingsOptions}
                                value={programmingsValues}
                                placeholder=""
                                onChange={(e) => handleProgrammingsSelected(e as SelectOptionModel[])}
                                isMulti
                            />
                        </Box>
                    }
                </Box>
            </>;
        }
        else {
            partialReturn =
                <Box display="flex" flexDirection="column" textAlign="center" alignItems="center">
                    <ReportProblemIcon className="warning-icon" />
                    <Box className="message">{Translate.Resources.UI_WorksFilter_message1}</Box>
                    {props.shouldDisplaySecondMessage && <Box className="message">{Translate.Resources.UI_WorksFilter_message2}</Box>}
                </Box>;
        }

        ret =
            <>
                {partialReturn}
                <Box className="btn-actions" display="flex" flexDirection="row" justifyContent="flex-end">
                    <Button className="btn-secondary" onClick={props.onClose}>
                        {Translate.Resources.UI_WorksFilter_Cancel}
                    </Button>
                    <Button className={`btn-primary ${programmingsOptions.length === 0 ? 'diabled' : ''}`} onClick={handleValidate} disabled={programmingsOptions.length === 0}>
                        {Translate.Resources.UI_WorksFilter_Validate}
                    </Button>
                </Box>
            </>;
    }

    return (
        <ClickAwayListener onClickAway={props.onClose}>
            <Popper open={props.open} anchorEl={props.anchorEl} className="poper-menu works-filter">
                {ret}
            </Popper>
        </ClickAwayListener>
    )
}