import { mdiCursorDefault } from '@mdi/js';
import Icon from '@mdi/react';
import { IconButton } from '@mui/material';
import { Box } from '@mui/system';
import { isLineInPoly } from '@turf/boolean-contains';
import { LineString, Polygon } from '@turf/turf';
import atlas, { data, layer, Map as AzureMap, MapMouseEvent, Shape } from 'azure-maps-control';
import { control, drawing } from 'azure-maps-drawing-tools';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import iconCar from 'src/assets/icons/icon-car.svg';
import Translate, { Localization } from '../../../localization/Localization';
import { ensureCustomMapChoice } from '../../Map/MapUtils';
import { CustomMapChoice } from '../../models/CustomMapChoice';
import { MapCursorMode } from '../../models/MapCursorMode';
import { MeasurementSystemType } from '../../models/MeasurementSystemType';
import './MapActionBar.scss';
import { MapStyleSelector } from './MapStyleSelector/MapStyleSelector';

const selectionDrawElementId = "selection-draw";
const deselectionDrawElementId = "deselection-draw";
const measureLengthDrawElementId = "measure-draw";

export enum barAction {
    None,
    carSelect,
    MonoSelect,
    ZoneSelect,
    ClearZone,
    Measure
}

interface mapSections {
    roadSectionId: number,
    pathGeometry: data.LineString
}

interface MapActionBarProps {
    azureMap: atlas.Map,
    actions: barAction[],
    sections: Map<number, mapSections>
    selectedSectionsId: number[],
    mainLayer: string,
    currentMeasurementSystemType: MeasurementSystemType,
    selectedDefaultAction?: barAction,
    onSelectedSectionChange: (sectionsId: number[]) => void,
    handleDisplayImageFromSectionClicked?: (event: MapMouseEvent) => void,
    handleCLoseRoadSectionDetails?: () => void
}

export const MapActionBar = (props: MapActionBarProps): JSX.Element => {

    const [selectedAction, setSelectedAction] = useState<barAction>(barAction.None);
    let selectedSectionsIdRef = useRef([]);
    let selectionDrawingManager = useRef<drawing.DrawingManager>();
    let deselectionDrawingManager = useRef<drawing.DrawingManager>();
    let measureDrawingManager = useRef<drawing.DrawingManager>();
    let currentMeasurementSystemType = useRef<MeasurementSystemType>();
    let selectedDefaultAction = useRef<barAction>(barAction.None);

    const handleMapClick = useCallback(
        (event: MapMouseEvent) => {
            let updatedSelectedSectionsId = Array.from(selectedSectionsIdRef.current);
            let clickedSectionId: number = null;
            let shape = (event.shapes[0] as any);
            if (shape) {
                clickedSectionId = shape.id ?? shape?.data?.properties?.RoadSectionId;

                if (updatedSelectedSectionsId.includes(clickedSectionId)) {
                    updatedSelectedSectionsId = updatedSelectedSectionsId.filter(e => e !== clickedSectionId);
                }
                else {
                    updatedSelectedSectionsId.push(clickedSectionId)
                }
                props.onSelectedSectionChange(updatedSelectedSectionsId);
            }
        }, [])

    const handleDisplayImageClicked = useCallback(
        (event: MapMouseEvent) => {
            props.handleDisplayImageFromSectionClicked(event);
        }, [])

    const handleLayerMouseover = useCallback(
        () => {
            setMapCursor(props.azureMap, MapCursorMode.Pointer);
        }, [])

    const handleLayerMouseoverWhenDisplayImage = useCallback(
        () => {
            setMapCursor(props.azureMap, MapCursorMode.Car);
        }, [])

    const handleLayerMouseout = useCallback(
        () => {
            setMapCursor(props.azureMap, MapCursorMode.Auto);
        }, [])

    useEffect(() => {
        if (!props.azureMap)
            return;

        const mainLayer = props.azureMap.layers.getLayerById(props.mainLayer) as layer.LineLayer;

        if (selectedAction === barAction.carSelect) {
            setMapCursor(props.azureMap, MapCursorMode.Auto);
            props.azureMap.events.remove('mousedown', handleMapClick);
            props.azureMap.events.remove('mouseover', mainLayer, handleLayerMouseover);

            props.azureMap.events.add('mousedown', handleDisplayImageClicked);
            props.azureMap.events.add('mouseover', mainLayer, handleLayerMouseoverWhenDisplayImage);
            props.azureMap.events.add('mouseout', mainLayer, handleLayerMouseout);

            selectionDrawingManager.current?.setOptions({ mode: drawing.DrawingMode.idle });
            deselectionDrawingManager.current?.setOptions({ mode: drawing.DrawingMode.idle });
            measureDrawingManager.current?.setOptions({ mode: drawing.DrawingMode.idle });
            measureDrawingManager.current?.getSource().clear();
            props.azureMap.setUserInteraction({ dragPanInteraction: true });
        }
        else if (selectedAction === barAction.MonoSelect) {
            setMapCursor(props.azureMap, MapCursorMode.Auto);
            props.azureMap.events.remove('mousedown', handleDisplayImageClicked);
            props.azureMap.events.remove('mouseover', mainLayer, handleLayerMouseoverWhenDisplayImage);

            props.azureMap.events.add('mousedown', handleMapClick);
            props.azureMap.events.add('mouseover', mainLayer, handleLayerMouseover);
            props.azureMap.events.add('mouseout', mainLayer, handleLayerMouseout);

            selectionDrawingManager.current?.setOptions({ mode: drawing.DrawingMode.idle });
            deselectionDrawingManager.current?.setOptions({ mode: drawing.DrawingMode.idle });
            measureDrawingManager.current?.setOptions({ mode: drawing.DrawingMode.idle });
            measureDrawingManager.current?.getSource().clear();
            document.getElementById('measurementInfo').innerHTML = null;
            props.azureMap.setUserInteraction({ dragPanInteraction: true });
        }
        else if (selectedAction === barAction.ZoneSelect) {
            setMapCursor(props.azureMap, MapCursorMode.Crosshair);
            props.azureMap.events.remove('mousedown', handleDisplayImageClicked);
            props.azureMap.events.remove('mousedown', handleMapClick);
            props.azureMap.events.remove('mouseover', mainLayer, handleLayerMouseover);
            props.azureMap.events.remove('mouseover', mainLayer, handleLayerMouseoverWhenDisplayImage);
            props.azureMap.events.remove('mouseout', mainLayer, handleLayerMouseout);

            deselectionDrawingManager.current?.setOptions({ mode: drawing.DrawingMode.idle });
            measureDrawingManager.current?.setOptions({ mode: drawing.DrawingMode.idle });
            props.azureMap.setUserInteraction({ dragPanInteraction: false });
        }
        else if (selectedAction === barAction.ClearZone) {
            setMapCursor(props.azureMap, MapCursorMode.Crosshair);
            props.azureMap.events.remove('mousedown', handleMapClick);
            props.azureMap.events.remove('mousedown', handleDisplayImageClicked);
            props.azureMap.events.remove('mouseover', mainLayer, handleLayerMouseover);
            props.azureMap.events.remove('mouseover', mainLayer, handleLayerMouseoverWhenDisplayImage);
            props.azureMap.events.remove('mouseout', mainLayer, handleLayerMouseout);

            selectionDrawingManager.current?.setOptions({ mode: drawing.DrawingMode.idle });
            measureDrawingManager.current?.setOptions({ mode: drawing.DrawingMode.idle });
            props.azureMap.setUserInteraction({ dragPanInteraction: false });
        }
        else if (selectedAction === barAction.Measure) {
            setMapCursor(props.azureMap, MapCursorMode.Crosshair);
            props.azureMap.events.remove('mousedown', handleMapClick);
            props.azureMap.events.remove('mousedown', handleDisplayImageClicked);
            props.azureMap.events.remove('mouseover', mainLayer, handleLayerMouseover);
            props.azureMap.events.remove('mouseover', mainLayer, handleLayerMouseoverWhenDisplayImage);
            props.azureMap.events.remove('mouseout', mainLayer, handleLayerMouseout);

            selectionDrawingManager.current?.setOptions({ mode: drawing.DrawingMode.idle });
            deselectionDrawingManager.current?.setOptions({ mode: drawing.DrawingMode.idle });
            props.azureMap.setUserInteraction({ dragPanInteraction: true });
        }
        else {
            setMapCursor(props.azureMap, MapCursorMode.Auto);
            props.azureMap.events.remove('mousedown', handleMapClick);
            props.azureMap.events.remove('mousedown', handleDisplayImageClicked);
            props.azureMap.events.remove('mouseover', mainLayer, handleLayerMouseover);
            props.azureMap.events.remove('mouseover', mainLayer, handleLayerMouseoverWhenDisplayImage);
            props.azureMap.events.remove('mouseout', mainLayer, handleLayerMouseout);

            selectionDrawingManager.current?.setOptions({ mode: drawing.DrawingMode.idle });
            deselectionDrawingManager.current?.setOptions({ mode: drawing.DrawingMode.idle });
            measureDrawingManager.current?.setOptions({ mode: drawing.DrawingMode.idle });
            props.azureMap.setUserInteraction({ dragPanInteraction: true });
        }
    }, [selectedAction]);

    useEffect(() => {
        selectedSectionsIdRef.current = props.selectedSectionsId
    }, [props.selectedSectionsId]);

    useEffect(() => {
        currentMeasurementSystemType.current = props.currentMeasurementSystemType
    }, [props.currentMeasurementSystemType]);

    useEffect(() => {
        selectedDefaultAction.current = props.selectedDefaultAction
        setSelectedAction(selectedDefaultAction.current);
    }, [props.selectedDefaultAction]);

    const updateAction = (action: barAction) => {
        if (selectedAction === barAction.carSelect) {
            props.handleCLoseRoadSectionDetails();
        }

        if (action === barAction.carSelect) {
            setSelectedAction(selectedAction === barAction.carSelect ? barAction.None : barAction.carSelect);
        }
        else if (action === barAction.MonoSelect) {
            setSelectedAction(selectedAction === barAction.MonoSelect ? barAction.None : barAction.MonoSelect);
        }
        else if (action === barAction.ZoneSelect) {
            setSelectedAction(selectedAction === barAction.ZoneSelect ? barAction.None : barAction.ZoneSelect);
        }
        else if (action === barAction.ClearZone) {
            setSelectedAction(selectedAction === barAction.ClearZone ? barAction.None : barAction.ClearZone);
        }
        else if (action === barAction.Measure) {
            props.azureMap.setUserInteraction({ dragPanInteraction: true });
            if (measureDrawingManager.current?.getOptions().mode === "idle")
                setMapCursor(props.azureMap, MapCursorMode.Auto);
            else
                setMapCursor(props.azureMap, MapCursorMode.Crosshair);
            setSelectedAction(barAction.Measure);
        }
    }

    const handleMapStyleChange = (map) => (mapChoice: CustomMapChoice) => ensureCustomMapChoice(map, mapChoice);

    useEffect(() => {
        setMapCursor(props.azureMap, MapCursorMode.Auto);

        props.azureMap.events.add('ready', () => {

            if (props.actions.includes(barAction.ZoneSelect)) {
                let newSelectionDrawingManager = createDrawingManager(props.azureMap, selectionDrawElementId);
                props.azureMap.events.add('drawingmodechanged', newSelectionDrawingManager, drawingModeChanged);
                props.azureMap.events.add('drawingcomplete', newSelectionDrawingManager, (drawingShape: Shape) => selectPolygons(drawingShape, newSelectionDrawingManager));
                selectionDrawingManager.current = newSelectionDrawingManager;
                setSelectionOrDeselectionElementTitle(selectionDrawElementId, selectionDrawingManager.current);

            }
            if (props.actions.includes(barAction.ClearZone)) {
                let newDeselectionDrawingManager = createDrawingManager(props.azureMap, deselectionDrawElementId);
                props.azureMap.events.add('drawingmodechanged', newDeselectionDrawingManager, drawingModeChanged);
                props.azureMap.events.add('drawingcomplete', newDeselectionDrawingManager, (drawingShape: Shape) => unselectPolygons(drawingShape, newDeselectionDrawingManager));
                deselectionDrawingManager.current = newDeselectionDrawingManager;
                setSelectionOrDeselectionElementTitle(deselectionDrawElementId, deselectionDrawingManager.current);

            }
            if (props.actions.includes(barAction.Measure)) {
                let newMeasureDrawingManager = createDrawingManagerMeasure(props.azureMap, measureLengthDrawElementId);
                props.azureMap.events.add('drawingmodechanged', newMeasureDrawingManager, drawingModeChanged);
                props.azureMap.events.add('drawingstarted', newMeasureDrawingManager, drawingStarted);
                props.azureMap.events.add('drawingchanging', newMeasureDrawingManager, measureShape);
                props.azureMap.events.add('drawingchanged', newMeasureDrawingManager, measureShape);
                props.azureMap.events.add('drawingcomplete', newMeasureDrawingManager, drawingComplete);
                measureDrawingManager.current = newMeasureDrawingManager;
                setMeasureElementTitle(measureLengthDrawElementId, measureDrawingManager.current);
            }
        });

        return function cleanup() {
            const mainLayer = props.azureMap.layers.getLayerById(props.mainLayer) as layer.LineLayer;
            setMapCursor(props.azureMap, MapCursorMode.Auto);
            props.azureMap.events.remove('mousedown', handleDisplayImageClicked);
            props.azureMap.events.remove('mousedown', handleMapClick);
            props.azureMap.events.remove('mouseover', mainLayer, handleLayerMouseoverWhenDisplayImage);
            props.azureMap.events.remove('mouseover', mainLayer, handleLayerMouseover);
            props.azureMap.events.remove('mouseout', mainLayer, handleLayerMouseout);
            selectionDrawingManager.current?.setOptions({ mode: drawing.DrawingMode.idle });
            deselectionDrawingManager.current?.setOptions({ mode: drawing.DrawingMode.idle });
            measureDrawingManager.current?.setOptions({ mode: drawing.DrawingMode.idle });
            props.azureMap.setUserInteraction({ dragPanInteraction: true });
            measureDrawingManager.current?.getSource().clear();
            if (document.getElementById('measurementInfo'))
                document.getElementById('measurementInfo').innerHTML = null;
        };
    }, []);

    const createDrawingManager = (map: AzureMap, containerId: string): drawing.DrawingManager => {

        let drawingManager = new drawing.DrawingManager(map, {
            interactionType: drawing.DrawingInteractionType.hybrid,
            toolbar: new control.DrawingToolbar({
                buttons: ['draw-polygon'],
                position: 'top-left',
                style: 'light',
                containerId: containerId,
                visible: true,
            }),
            //le freehand ne fonctionne pa. à investiguer @NFR @CMA
            //https://samples.azuremaps.com/drawing-tools-module/drawing-manager-options
            //https://github.com/Azure-Samples/AzureMapsCodeSamples/blob/main/Samples/Drawing%20Tools%20Module/Drawing%20manager%20options/Drawing%20manager%20options.html
            // interactionType: drawing.DrawingInteractionType.freehand,
            // shapeDraggingEnabled: false,
            // shapeRotationEnabled: false
        },
        );

        return drawingManager;
    }

    const createDrawingManagerMeasure = (map: AzureMap, containerId: string): drawing.DrawingManager => {

        let drawingManager = new drawing.DrawingManager(map, {
            interactionType: drawing.DrawingInteractionType.click,
            toolbar: new control.DrawingToolbar({
                buttons: ['draw-line', 'draw-polygon'],
                position: 'top-left',
                style: 'light',
                containerId: containerId,
                visible: true,
                numColumns: 1,
            }),
            //le freehand ne fonctionne pa. à investiguer @NFR @CMA
            //https://samples.azuremaps.com/drawing-tools-module/drawing-manager-options
            //https://github.com/Azure-Samples/AzureMapsCodeSamples/blob/main/Samples/Drawing%20Tools%20Module/Drawing%20manager%20options/Drawing%20manager%20options.html
            // interactionType: drawing.DrawingInteractionType.freehand,
            // shapeDraggingEnabled: false,
            // shapeRotationEnabled: false
        },
        );

        return drawingManager;
    }

    const selectPolygons = (drawingShape: Shape, drawingManager: drawing.DrawingManager) => {
        let updatedSelectedSectionsId = Array.from(selectedSectionsIdRef.current)
        let drawingGeometry = drawingShape.toJson().geometry as Polygon;
        props.sections.forEach(section => {

            let isLineInPolygon: boolean = isLineInPoly(drawingGeometry, section.pathGeometry as LineString);
            if (isLineInPolygon && !selectedSectionsIdRef.current.includes(section.roadSectionId)) {
                updatedSelectedSectionsId.push(section.roadSectionId);
            }
        })

        props.onSelectedSectionChange(updatedSelectedSectionsId);
        drawingManager.getSource().clear();
    }

    const unselectPolygons = (drawingShape: Shape, drawingManager: drawing.DrawingManager) => {
        let updatedSelectedSectionsId = Array.from(selectedSectionsIdRef.current)
        let drawingGeometry = drawingShape.toJson().geometry as Polygon;
        let linesToRemove: number[] = []
        props.sections.forEach(section => {

            let isLineInPolygon: boolean = isLineInPoly(drawingGeometry, section.pathGeometry as LineString);
            if (isLineInPolygon) {
                linesToRemove.push(section.roadSectionId);
            }
        })

        updatedSelectedSectionsId = updatedSelectedSectionsId.filter(e => !linesToRemove.includes(e));
        props.onSelectedSectionChange(updatedSelectedSectionsId);
        drawingManager.getSource().clear();
    }

    const drawingModeChanged = (mode) => {
        if (mode.startsWith('draw')) {
            selectionDrawingManager.current?.getSource().clear();
            measureDrawingManager.current?.getSource().clear();
            document.getElementById('measurementInfo').innerHTML = null;
        }
    }

    const setMapCursor = (map: AzureMap, cursor: MapCursorMode): void => {
        let classList = map.getCanvasContainer().classList;

        const cursorPointerClassName = "cursor-pointer";
        const cursorAutoClassName = "cursor-auto";
        const cursorCrosshairClassName = "cursor-crosshair";
        const cursorCarClassName = "cursor-car";

        let cursorClassName = null;

        switch (cursor) {
            case MapCursorMode.Car:
                cursorClassName = cursorCarClassName;
                break;
            case MapCursorMode.Pointer:
                cursorClassName = cursorPointerClassName;
                break;
            case MapCursorMode.Crosshair:
                cursorClassName = cursorCrosshairClassName;
                break;
            case MapCursorMode.Auto:
            default:
                cursorClassName = cursorAutoClassName;
                break;
        }

        if (cursorClassName !== cursorCarClassName && classList.contains(cursorCarClassName)) {
            classList.remove(cursorCarClassName);
        }
        if (cursorClassName !== cursorAutoClassName && classList.contains(cursorAutoClassName)) {
            classList.remove(cursorAutoClassName);
        }
        if (cursorClassName !== cursorCrosshairClassName && classList.contains(cursorCrosshairClassName)) {
            classList.remove(cursorCrosshairClassName);
        }
        if (cursorClassName !== cursorPointerClassName && classList.contains(cursorPointerClassName)) {
            classList.remove(cursorPointerClassName);
        }

        if (!classList.contains(cursorClassName)) {
            classList.add(cursorClassName);
        }
    }

    const setSelectionOrDeselectionElementTitle = (containerElementId: string, drawingManager: drawing.DrawingManager): void => {
        let drawElement = document.getElementById(containerElementId)?.children[0];
        if (drawElement) {
            let polygonElement = drawElement.children[0];
            if (drawingManager === selectionDrawingManager.current) {
                polygonElement.setAttribute("title", Translate.Resources.UI_Map_Action_Bar_Selection_Zone);
            }
            else if (drawingManager === deselectionDrawingManager.current) {
                polygonElement.setAttribute("title", Translate.Resources.UI_Map_Action_Bar_Unselection_Zone);
            }
        }
    }

    const setMeasureElementTitle = (containerElementId: string, drawingManager: drawing.DrawingManager): void => {
        let drawElement = document.getElementById(containerElementId)?.children[0];
        if (drawElement) {
            let measureDistanceElement = drawElement.children[0];
            let measureAreaElement = drawElement.children[1];
            if (drawingManager === measureDrawingManager.current) {
                measureDistanceElement.setAttribute("title", Translate.Resources.UI_Measure_Distance_Tooltip);
                measureAreaElement.setAttribute("title", Translate.Resources.UI_Measure_Area_Tooltip);
            }
        }
    }


    function drawingComplete(shape) {
        measureShape(shape);
    }

    function drawingStarted(shape) {
        if (measureDrawingManager.current?.getSource().getShapes().length >= 2) {
            measureDrawingManager.current?.getSource().removeById(measureDrawingManager.current?.getSource().getShapes()[0].getId())
        }
    }

    function measureShape(shape) {
        let msg = '';
        let g = shape.toJson().geometry;
        let polygon;

        switch (shape.getType()) {
            case 'LineString':
                if (currentMeasurementSystemType.current === MeasurementSystemType.Imperial) {
                    let l = Math.round(atlas.math.getLengthOfPath(g.coordinates, 'yards') * 100) / 100;
                    if (Localization.locale === "fr") {
                        msg += `${Translate.Resources.UI_Measure_Distance} : ${l.toString().replace(/\./g, ',')} yd`;
                    }
                    else {
                        msg += `${Translate.Resources.UI_Measure_Distance}: ${l} yd`;
                    }
                }
                else {
                    let l = Math.round(atlas.math.getLengthOfPath(g.coordinates, 'meters') * 100) / 100;
                    if (Localization.locale === "fr") {
                        msg += `${Translate.Resources.UI_Measure_Distance} : ${l.toString().replace(/\./g, ',')} m`;
                    }
                    else {
                        msg += `${Translate.Resources.UI_Measure_Distance}: ${l} m`;
                    }

                }

                //Polygon's are rendered as lines when initially being drawn. 
                if (measureDrawingManager.current?.getOptions().mode === 'draw-polygon') {
                    polygon = new atlas.data.Polygon(g.coordinates);
                }
                break;
            case 'Polygon':
                polygon = g;
                if (currentMeasurementSystemType.current === MeasurementSystemType.Imperial) {
                    let p = Math.round(atlas.math.getLengthOfPath(g.coordinates[0], 'yards') * 100) / 100;
                    if (Localization.locale === "fr") {

                        msg = `${Translate.Resources.UI_Measure_Perimeter} : ${p.toString().replace(/\./g, ',')} yd`;
                    }
                    else {
                        msg = `${Translate.Resources.UI_Measure_Perimeter}: ${p} yd`;
                    }
                }

                else {
                    let p = Math.round(atlas.math.getLengthOfPath(g.coordinates[0], 'meters') * 100) / 100;
                    if (Localization.locale === "fr") {
                        msg = `${Translate.Resources.UI_Measure_Perimeter} : ${p.toString().replace(/\./g, ',')} m`;
                    }
                    else {

                        msg = `${Translate.Resources.UI_Measure_Perimeter}: ${p} m`;
                    }

                }
                break;
        }
        if (polygon) {
            if (currentMeasurementSystemType.current === MeasurementSystemType.Imperial) {
                if (Localization.locale === "fr") {
                    msg += `<br/>${Translate.Resources.UI_Measure_Area} : ${atlas.math.getArea(polygon, 'squareYards', 2).toString().replace(/\./g, ',')} sq.yd`;
                }
                else {
                    msg += `<br/>${Translate.Resources.UI_Measure_Area}: ${atlas.math.getArea(polygon, 'squareYards', 2)} sq.yd`;
                }
            }

            else {
                if (Localization.locale === "fr") {
                    msg += `<br/>${Translate.Resources.UI_Measure_Area} : ${atlas.math.getArea(polygon, 'squareMeters', 2).toString().replace(/\./g, ',')} m²`;
                }
                else {
                    msg += `<br/>${Translate.Resources.UI_Measure_Area}: ${atlas.math.getArea(polygon, 'squareMeters', 2)} m²`;
                }
            }

        }
        document.getElementById('measurementInfo').innerHTML = msg;
    }

    useEffect(() => {
        return function cleanup() {
            if (measureDrawingManager?.current) {
                measureDrawingManager.current.getSource().clear();
            }
        }
    }, [])

    return (
        <Box display="flex" flexDirection="column" className="bar-action">
            <MapStyleSelector handleMapStyleChange={handleMapStyleChange(props.azureMap)} />
            {props.actions.includes(barAction.carSelect) &&
                <Box display="flex" className={`car-icon ${selectedAction === barAction.carSelect ? 'selected' : ''}`}>
                    <IconButton onClick={(e) => updateAction(barAction.carSelect)}>
                        <img src={iconCar} alt="car icon" />
                    </IconButton>
                </Box>
            }
            {props.actions.includes(barAction.MonoSelect) &&
                <Box className={selectedAction === barAction.MonoSelect ? "action bottom-margin top selected" : "action bottom-margin top "} onClick={(e) => updateAction(barAction.MonoSelect)}>
                    <Icon title={Translate.Resources.UI_Map_Action_Bar_selection_Unit} path={mdiCursorDefault} size={1} />
                </Box>
            }
            {props.actions.includes(barAction.ZoneSelect) &&
                <Box id={selectionDrawElementId} className={selectedAction === barAction.ZoneSelect ? "selected bottom-margin " : "unselected bottom-margin"} onClick={(e) => updateAction(barAction.ZoneSelect)}>
                </Box>

            }
            {props.actions.includes(barAction.ClearZone) &&
                <Box id={deselectionDrawElementId} className={selectedAction === barAction.ClearZone ? "selected bottom-margin" : "unselected bottom-margin"} onClick={(e) => updateAction(barAction.ClearZone)}>
                </Box>
            }
            {props.actions.includes(barAction.Measure) &&
                <Box id={measureLengthDrawElementId} className={selectedAction === barAction.Measure ? "selected bottom-margin" : "unselected bottom-margin"} onClick={(e) => updateAction(barAction.Measure)}>
                </Box>
            }
        </Box>);
}